<template>
  <Icon />
</template>

<script lang="ts" setup>
import type { IconName } from '@/types/generated/icons.gen'
import { defineAsyncComponent } from 'vue'

// 新加 svg 后，需要执行`pnpm gen`来更新类型定义
const props = defineProps<{ name: IconName }>()

const Icon = defineAsyncComponent(async () => {
  return import(`../assets/icons/${props.name}.svg`)
})
</script>

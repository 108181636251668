<template>
  <div class="flex flex-col h-full">
    <SafeAreaTopSpacer class="bg-ld-background" />
    <UnpublishedPackage
      v-if="isPackageUpdateUnreleased(props.package)"
      :package="props.package"
      @on-update="onUpdate"
    />

    <div class="vstack absolute right-4 top-18 z-1">
      <DebugLabel>cardId:{{ activeCardResponse?.id }}</DebugLabel>
      <DebugLabel>pkgId:{{ props.package.id }}</DebugLabel>

      <DebugButton
        label="队列调试"
        @click="onDebugQueue"
      />

      <DebugButton
        v-if="data.cards.length > 0"
        :label="_t('common.match_card')"
        @click="onDisplayMatchCard"
      />
    </div>

    <div
      class="vstack items-stretch gap-0 bg-ld-background flex-1 overflow-hidden"
    >
      <div class="header flex items-center relative">
        <i
          class="pi pi-arrow-left cursor-pointer absolute"
          @click="onBack"
        ></i>

        <Breadcrumb
          class="mx-auto bg-[transparent]"
          :model="breadCrumbItems"
        >
          <template #item="{ item, label }">
            <button @click="onBreadCrumbClick(item)">
              {{ label }}
            </button>
          </template>
        </Breadcrumb>

        <Button
          class="h-32px"
          :disabledMsg="
            _t('learn.card_require', { count: _global.minCardCountToLearn })
          "
          :disabled="props.package.cardCount < _global.minCardCountToLearn"
          @click="onChallenge"
        >
          <span class="text-13px">{{ _t('learn.to_atlas') }}</span>
        </Button>
      </div>

      <Divider class="my-0" />

      <div class="flex flex-1 overflow-hidden">
        <div
          class="w-[20%] flex-1 overflow-y-scroll overflow-x-hidden min-w-300px max-w-375px"
        >
          <ChapterTree
            :chapterCardsMap="cardListCache"
            :package="props.package"
            :selectedCardId="data.selectedCardId"
            :selectedChapterId="data.selectedChapterId"
            @chapterSelect="onChapterSelect"
            @cardSelect="onCardLocate"
            @renameChapter="onChapterRename"
            @deleteChapter="onChapterDelete"
            @moveChapter="onChapterMove"
            @fetchCards="onChapterCardsFetch"
          />
        </div>

        <Divider
          layout="vertical"
          class="mx-0"
        />

        <div class="flex-1 flex overflow-y-auto">
          <div class="w-full flex h-full">
            <SlickList
              ref="slickList"
              :list="data.cards"
              axis="y"
              class="h-full flex-1 max-w-816px min-w-376px mx-auto flex flex-col"
              useDragHandle
              @sortEnd="onCardsSortEnd"
            >
              <Loading
                v-if="cardsLoading"
                class="flex-1 h-full"
              />

              <CardList
                v-else
                ref="cardList"
                class="flex-1 h-full"
                :package="props.package"
                :chapterId="data.selectedChapterId"
                :cards="data.cards"
                @chapterClick="
                  (chapter: ChapterItem) =>
                    onChildChapterClick(String(chapter.id))
                "
                @createCard="onCardDraftCreate(data.cards.length)"
                @ai-generate="onAiGenerate()"
                @scroll="onCardListScroll"
              >
                <template #card="{ cardRes, noteIndex: cardIndex }">
                  <SlickItem :index="cardIndex">
                    <div
                      v-if="cardIndex > 0"
                      class="mid-create-btn"
                    >
                      <Icon
                        v-if="isOwner"
                        name="mid-card-create"
                        class="w-32px"
                        @click="onCardDraftCreate(cardIndex)"
                      />
                    </div>

                    <CardPad
                      v-if="
                        isOwner &&
                        (data.cardFocus === cardIndex ||
                          invalidCards[cardRes.id])
                      "
                      :showFullEdit="!_global.isProd"
                      :canDelete="canCardDelete(cardRes)"
                      :canSwitchCardType="props.package.cardType == null"
                      :packageId="props.package.id"
                      :cardDraft="cardRes"
                      :cards="data.cards"
                      :class="{
                        'card-focus': data.cardFocus === cardIndex,
                      }"
                      @create-after="onCardDraftCreate(cardIndex + 1)"
                      @create-before="onCardDraftCreate(cardIndex)"
                      @copy="
                        onCardCopy(data.selectedChapterId, cardRes.id, cardRes)
                      "
                      @delete="onCardDraftDelete(cardRes, cardIndex)"
                      @focus="onCardFocus(cardIndex + $event)"
                      @update="onCardDraftUpdate(cardRes, cardIndex, $event)"
                      @prev="onPrevCard"
                      @next="onNextCard"
                      @add-illustration="
                        onIllustrationAdd(cardRes, cardIndex, $event)
                      "
                      @remove-illustration="onIllustrationRemove(cardRes)"
                      @change-card-type="defaultCardType = $event"
                    />

                    <CardBrowserPad
                      v-else
                      :key="cardRes.id"
                      :class="[
                        {
                          'card-focus': data.cardFocus === cardIndex,
                          'g-card-highlight': data.highlightCards[cardRes.id],
                          'hover:border-gray': isOwner,
                        },
                      ]"
                      :cardResponse="cardRes"
                      :learn-count="data.cardLearnCountMap[cardRes.id]"
                      @click="onCardSelect(cardRes.id, false)"
                    />
                  </SlickItem>
                </template>
              </CardList>
            </SlickList>

            <Divider
              layout="vertical"
              class="mx-0"
            />

            <div class="w-[25%] min-w-300px px-4 max-w-375px pb-4">
              <div
                ref="panelWrapper"
                class="h-full vstack items-center overflow-hidden"
              >
                <div
                  v-if="activeCard && activeCardResponse && isOwner"
                  ref="panelContent"
                  class="rounded-8px shadow-lg w-full bg-white"
                  :style="{
                    marginTop: `${floatingPanelTop}px`,
                  }"
                >
                  <DistractorPanel
                    v-if="activeCard.type === CardType.CLOZE"
                    :cardId="activeCardResponse.id"
                    :card="activeCard"
                    @update:distractors="onCardDistractorsUpdate"
                  ></DistractorPanel>

                  <MCQEditPanel
                    v-if="activeCard.type === CardType.MCQ"
                    :key="activeCardResponse.id"
                    :cardId="activeCardResponse.id"
                    :card="activeCard"
                    @update="onMCQCardUpdate"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Loading from '@/components/Loading.vue'
import Breadcrumb from 'primevue/breadcrumb'
import { onMounted, onUnmounted, reactive, ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  fetchCards,
  deleteCard,
  createCard,
  CardCreatedType,
  updateCard,
  moveCard,
  CardUpdatedType,
  fetchCardsStats,
  CardTypeName,
} from '@/api/package-source'
import { type CardSchedule } from '@/api/learn'

import ChapterTree from '@/components/package/ChapterTree.vue'
import {
  getChapterAncestors,
  getChapterChildren,
  isPackageUpdateUnreleased,
  isChapterExists,
} from '@/utils/package'
import { useCommonStore } from '@/stores'
import DebugLabel from '@/components/DebugLabel.vue'
import { ROOT_CHAPTER_KEY, CardType, VirtualCardType } from '@/types/core'
import {
  getCardTitleForDialog,
  newClozeCard,
  newMCQCard,
  newWordCard,
  updateClozeCardContentDistractors,
  validateCard,
  type CardDraft,
} from '@/utils/card'
import { debounce, mapValues, shuffle } from 'lodash-es'
import CardPad from '@/components/package/CardPad/CardPad.vue'
import CardBrowserPad from '@/components/package/CardBrowserPad.vue'
import { SlickList, SlickItem } from 'vue-slicksort'

import type { MenuItem } from 'primevue/menuitem'
import type { ClozeCard, EnWordCard, MCQCard } from '@/types/core'
import type {
  Package,
  CardResponse as CardResponse,
  ChapterItem,
  MoveChapterRequest,
  PackageBasic,
} from '@/api/package-source'
import CardQueueSelect from '@/components/debug/CardQueueSelect.vue'
import type { DragPosition } from '@/components/Tree/Tree.vue'
import type { Card } from '@/types/core'
import dayjs from 'dayjs'
import CardList from '@/components/package/CardList.vue'
import UnpublishedPackage from '@/components/UnpublishedPackageHeader.vue'
import AICardGenerate from '@/components/package/AICardGenerate.vue/AICardGenerate.vue'
import { useHotKey } from '@/hooks'
import DistractorPanel, {
  type ClozeGroup,
} from '@/components/DistractorPanel/DistractorPanel.vue'
import type { OpenDialogPromise } from '@/types/global'
import { convertCardNameToCardType } from '@/shared'
import MCQEditPanel from '@/components/MCQEditPanel.vue'
import Boss from '@/components/VirtualCard/Boss.vue'
import { randomPickBoss } from '@/utils'

const router = useRouter()
const route = useRoute()
const store = useCommonStore()

useHotKey('ctrl+k,command+k', onAiGenerate)

const props = defineProps<{
  package: Package
}>()

const emit = defineEmits<{
  chapterCountChange: [string, number]
  chapterUpdate: [chapterId: string, chapter?: ChapterItem]
}>()

const defaultCardType = ref(
  convertCardNameToCardType(
    props.package.owned?.defaultCardType ??
      props.package.cardType ??
      CardTypeName.CLOZE
  )
)

const packageState = store.packageState[props.package.id] ?? {}

function getQueryChapterId() {
  return route.query.chapterId as string | undefined
}

function getQueryCardId() {
  return route.query.cardId as string | undefined
}

const cardListCache = ref<Record<string, CardResponse[]>>({})

// 记录 note 是否正在创建中
const noteCardCreateMap = new Map<CardResponse, boolean>()
const noteCardCreateDeboucingMap = new Map<CardResponse, boolean>()

const data = reactive({
  // 选中章节时展示的卡片列表
  cards: [] as CardDraft[],
  cardLearnCountMap: {} as Record<number, number>,
  selectedChapterId:
    getQueryChapterId() ?? packageState.selectedChapterId ?? ROOT_CHAPTER_KEY,
  selectedCardId: getQueryCardId() ?? packageState.selectedCardId,

  cardFocus: -1,
  cardListLoading: new Map<string, boolean>(),
  highlightCards: {} as Record<number, boolean>,
})

const slickList = ref()

// 如果编辑的卡片内容不合法，需要保持编辑器状态
// 这里用于存储哪些卡片未保存下来
const invalidCards = ref<Record<number, boolean>>({})

const selectedCardIdQuery = computed(() => {
  if (data.selectedCardId != null) {
    return parseInt(data.selectedCardId) > 0 ? data.selectedCardId : undefined
  }

  return undefined
})

const isOwner = computed(() => props.package?.owned != null)

const activeCardResponse = computed(() => {
  if (data.cardFocus < 0) return null

  return data.cards[data.cardFocus]
})

const activeCard = computed<Card | null>(() => {
  if (activeCardResponse.value != null) {
    return JSON.parse(activeCardResponse.value.content) as Card
  }

  return null
})

const cardsLoading = computed(() => {
  return data.cardListLoading.get(data.selectedChapterId)
})

const breadCrumbItems = computed<MenuItem[]>(() => {
  if (!props.package) return []

  const items: MenuItem[] = []
  const ancestors = getChapterAncestors(
    props.package.chapters,
    data.selectedChapterId
  )

  for (const ancestorId of ancestors) {
    const chapter =
      ancestorId === ROOT_CHAPTER_KEY
        ? {
            title: props.package.name,
            id: ROOT_CHAPTER_KEY,
          }
        : props.package.chapters[ancestorId]

    if (chapter == null) {
      break
    }

    items.push({
      label: chapter.title,
      key: String(chapter.id),
    })
  }
  return items
})

function canCardDelete(card: CardResponse) {
  // 已保存的卡片可以直接删除
  // 非最后一张草稿卡可以删除
  return card.id >= 0 || data.cards.length > 1
}

function onRouteChange() {
  data.selectedCardId = getQueryCardId()
  const newChapterId = getQueryChapterId() ?? ROOT_CHAPTER_KEY

  if (data.selectedCardId !== newChapterId && newChapterId != null) {
    fetchCardList(newChapterId)
  }
}

onMounted(() => {
  window.addEventListener('popstate', onRouteChange)
})

onUnmounted(() => {
  window.removeEventListener('popstate', onRouteChange)
})

if (!isChapterExists(props.package, data.selectedChapterId)) {
  onChapterSelect(ROOT_CHAPTER_KEY)
}

function onChildChapterClick(id: string) {
  onChapterSelect(id)
}

async function onChapterSelect(chapterId: string) {
  if (data.selectedChapterId !== chapterId) {
    fetchCardList(chapterId)
  }

  data.selectedChapterId = chapterId

  store.updatePackageState(props.package.id, {
    selectedChapterId: chapterId,
  })

  router.push({
    query: {
      ...route.query,
      chapterId: data.selectedChapterId,
      cardId: selectedCardIdQuery.value,
    },
  })
}

function updateSelectedCard(cardId: number | undefined) {
  data.selectedCardId = cardId == null ? undefined : cardId.toString()

  store.updatePackageState(props.package.id, {
    selectedCardId: cardId == null ? undefined : cardId.toString(),
  })

  router.push({
    query: {
      ...route.query,
      chapterId: data.selectedChapterId,
      cardId: selectedCardIdQuery.value,
    },
  })
}

function highlightCard(id: number) {
  data.highlightCards[id] = true
  setTimeout(() => {
    data.highlightCards[id] = false
  }, 1000)
}

const cardList = ref()
function onCardLocate(cardId: number | undefined) {
  data.cardFocus = -1
  const index = data.cards.findIndex(item => item.id === cardId)

  if (index > -1) {
    highlightCard(cardId!)
    scrollToCard(cardId!)
  }
}

function onCardSelect(cardId: number | undefined, focus: boolean = true) {
  updateSelectedCard(cardId)

  const index = data.cards.findIndex(item => item.id === cardId)

  data.cardFocus = index

  if (cardId && focus) {
    scrollToCard(cardId)
  }
}

function scrollToCard(cardId: number) {
  const index = data.cards.findIndex(item => item.id === cardId)

  if (cardList.value) {
    cardList.value.scrollToIndex(index)
  }
}

function onCardResUpdate(newCard: CardResponse) {
  const cards = cardListCache.value[data.selectedChapterId] ?? []
  const index = cards.findIndex(item => item.id === newCard.id)

  if (index > -1) {
    cards.splice(index, 1, newCard)
  }
}

// 每次新建一个卡片草稿时的 id
let cardRenderId = 0
function getCardRenderId(): number {
  cardRenderId++

  return cardRenderId
}

function onCardDraftCreate(targetIndex: number) {
  if (!isOwner.value) return

  let card: Card

  switch (defaultCardType.value) {
    case CardType.CLOZE:
      card = newClozeCard()
      break
    case CardType.EN_WORD:
      card = newWordCard()
      break
    case CardType.MCQ:
      card = newMCQCard()
      break
    default:
      card = newClozeCard()
  }

  const renderId = getCardRenderId()
  const newCardDraft: CardDraft = {
    renderId,
    id: -renderId,
    content: JSON.stringify(card),
    contentHash: 0,
    createdType: CardCreatedType.NORMAL,
    updatedType: CardUpdatedType.NORMAL,
    createdAt: dayjs().format('YYYY-DD-MM'),
    updatedAt: dayjs().format('YYYY-DD-MM'),
    authorId: '',
  }

  data.cards.splice(targetIndex, 0, newCardDraft)

  onCardSelect(newCardDraft.id)
}

let aiGenerateDialog: OpenDialogPromise<void> | null
function onAiGenerate() {
  if (aiGenerateDialog != null) {
    aiGenerateDialog.close()
    aiGenerateDialog = null
    return
  }

  aiGenerateDialog = _openDialog(AICardGenerate, {
    title: _t('AI 制卡'),
    props: {
      packageId: props.package.id,
      chapterId: data.selectedChapterId,
      cardType: props.package.cardType,
      onCardsCreated(cards: CardResponse[]) {
        cards.forEach(card => {
          data.cards.push({
            renderId: getCardRenderId(),
            ...card,
          })
        })

        cards.forEach(card => highlightCard(card.id))
        setTimeout(() => {
          const firstCard = cards[0]
          if (firstCard) {
            scrollToCard(firstCard.id)
          }
        })
      },
    },
    dialog: {
      pt: {
        content: {
          class: 'p-4 bg-ld-background min-w-700px',
        },
      },
    },
  })

  aiGenerateDialog.finally(() => {
    aiGenerateDialog = null
  })
}

function onIllustrationAdd(
  cardRes: CardResponse,
  cardIndex: number,
  assetId: string
) {
  const cardContent = JSON.parse(cardRes.content) as Card
  const card = {
    ...cardContent,
    illustration: assetId,
  } as Card
  cardRes.content = JSON.stringify(card)

  if (cardRes.id < 0) {
    let afterCardId: number | undefined = undefined
    let beforeCardId: number | undefined = undefined

    for (let index = cardIndex - 1; index >= 0; index--) {
      if (data.cards[index].id > 0) {
        afterCardId = data.cards[index].id
        break
      }
    }

    for (let index = cardIndex + 1; index < data.cards.length; index++) {
      if (data.cards[index].id > 0) {
        beforeCardId = data.cards[index].id
        break
      }
    }

    noteCardCreateDeboucingMap.set(cardRes, true)

    saveCreatedCard(cardRes, card, afterCardId, beforeCardId)
    return
  }

  saveUpdatedCard(cardRes.id, {
    ...cardContent,
    illustration: assetId,
  })
}

function onIllustrationRemove(cardRes: CardResponse) {
  const cardContent = JSON.parse(cardRes.content) as Card

  const newContent = {
    ...cardContent,
  }
  delete newContent.illustration
  cardRes.content = JSON.stringify(newContent)

  if (cardRes.id < 0) return
  saveUpdatedCard(cardRes.id, newContent)
}

let isDeleteConfirmOpen = false
async function onCardDraftDelete(cardRes: CardResponse, index: number) {
  if (isDeleteConfirmOpen) return

  function onDelete() {
    if (data.cardFocus > data.cards.length - 1) {
      onPrevCard()
    }

    if (cardRes.id > 0) {
      onCardDelete(data.selectedChapterId, cardRes.id)
    } else {
      data.cards.splice(index, 1)
    }
  }

  const needConfirm = cardRes.id >= 0

  if (needConfirm) {
    isDeleteConfirmOpen = true
    await _confirm({
      scene: 'warn',
      title: _t(`删除${getCardTitleForDialog(cardRes.content)}？`),
      content: _t('删除后无法恢复，请确认'),
      primaryText: _t('暂不'),
      secondaryText: _t('删除'),
      onSecondaryClick(resolve) {
        resolve(true)
        onDelete()
      },
    })

    isDeleteConfirmOpen = false
  } else {
    onDelete()
  }
}

const saveUpdatedCard = debounce((cardId: number, content: Card) => {
  if (validateCard(content)) {
    invalidCards.value[cardId] = true
    return
  }
  invalidCards.value[cardId] = false

  return updateCard(cardId, content).then(res => {
    if (res.code !== 0) {
      _message.info(res.message)
      return
    }
  })
}, 500)

const saveCreatedCard = debounce(
  (
    cardRes: CardResponse,
    card: Card,
    afterCardId?: number,
    beforeCardId?: number
  ) => {
    if (validateCard(card)) {
      invalidCards.value[cardRes.id] = true
      return
    }

    noteCardCreateDeboucingMap.set(cardRes, false)

    if (noteCardCreateMap.get(cardRes)) {
      return
    }

    noteCardCreateMap.set(cardRes, true)
    createCard({
      packageId: props.package.id,
      chapterId: data.selectedChapterId,
      content: card,
      afterCardId: afterCardId,
      beforeCardId: beforeCardId,
      createdType: CardCreatedType.NORMAL,
    }).then(res => {
      noteCardCreateMap.set(cardRes, false)

      if (res.code !== 0) {
        _message.info(res.message)
        return
      }

      if (data.selectedCardId === cardRes.id.toString()) {
        data.selectedCardId = res.data.id.toString()
      }

      cardRes.id = res.data.id
      cardRes.content = res.data.content
      emit('chapterCountChange', data.selectedChapterId, 1)
    })
  },
  500
)

function onCardDraftUpdate(
  cardRes: CardResponse,
  noteIndex: number,
  newCard: Card
) {
  cardRes.content = JSON.stringify(newCard)

  if (cardRes.id < 0) {
    let afterCardId: number | undefined = undefined
    let beforeCardId: number | undefined = undefined

    for (let index = noteIndex - 1; index >= 0; index--) {
      if (data.cards[index].id > 0) {
        afterCardId = data.cards[index].id
        break
      }
    }

    for (let index = noteIndex + 1; index < data.cards.length; index++) {
      if (data.cards[index].id > 0) {
        beforeCardId = data.cards[index].id
        break
      }
    }

    noteCardCreateDeboucingMap.set(cardRes, true)
    saveCreatedCard(cardRes, newCard, afterCardId, beforeCardId)
  } else {
    saveUpdatedCard(cardRes.id, newCard)
  }
}

function onCardFocus(index: number) {
  data.cardFocus = index
  const card = data.cards[index]
  updateSelectedCard(card.id)
}

function onPrevCard() {
  if (data.cardFocus > 0) {
    data.cardFocus--
    const card = data.cards[data.cardFocus]
    onCardSelect(card?.id)
  }
}

function onNextCard() {
  if (data.cardFocus < data.cards.length - 1) {
    data.cardFocus++
    const card = data.cards[data.cardFocus]
    onCardSelect(card?.id)
  } else {
    onCardDraftCreate(data.cards.length)
  }
}

function onBack() {
  router.push({
    name: 'shelf',
  })
}

function onBreadCrumbClick(item: MenuItem) {
  if (item.key) {
    onChapterSelect(item.key)
    onCardSelect(undefined)
  }
}

function onChapterRename(chapter: ChapterItem) {
  emit('chapterUpdate', chapter.id.toString(), chapter)
}

function onChapterDelete(chapterId: string) {
  const chapterItem = props.package.chapters[chapterId]
  emit('chapterUpdate', chapterId)

  cardListCache.value[chapterId] = []
  onChapterSelect(
    chapterItem.parentId != null
      ? String(chapterItem.parentId)
      : ROOT_CHAPTER_KEY
  )
}

async function onCardCopy(
  chapterId: string,
  cardId: number,
  card: CardResponse
): Promise<CardResponse | undefined> {
  if (cardId < 0) {
    _message.info(_t('卡片还未保存，稍后尝试'))
    return
  }

  const content = JSON.parse(card.content)

  return createCard({
    packageId: props.package.id,
    chapterId,
    content,
    afterCardId: cardId,
    createdType: CardCreatedType.DUPLICATE,
  }).then(res => {
    if (res.code === 0) {
      const cards = data.cards
      const index = cards.findIndex(item => item.id === cardId)

      if (index > -1) {
        cards.splice(index + 1, 0, {
          renderId: getCardRenderId(),
          ...res.data,
        })
      }

      return res.data
    } else {
      _message.info(res.message)
    }
  })
}

function onChapterMove({
  chapterId,
  parentChapterId,
  beforeChapterId,
}: MoveChapterRequest) {
  if (!props.package) return

  const chapterItem = props.package.chapters[chapterId]

  if (!chapterItem) return

  const parentChildren = getChapterChildren(
    props.package.chapters,
    parentChapterId
  )
  const existIndex = parentChildren.findIndex(
    item => String(item.id) === chapterId
  )

  if (existIndex > -1) {
    parentChildren.splice(existIndex, 1)
  }

  const index = beforeChapterId
    ? parentChildren.findIndex(item => String(item.id) === beforeChapterId)
    : parentChildren.length

  if (index > -1) {
    chapterItem.parentId =
      parentChapterId === ROOT_CHAPTER_KEY ? null : parseInt(parentChapterId)
    parentChildren.splice(index, 0, chapterItem)
  }

  for (let i = 0; i < parentChildren.length; i++) {
    parentChildren[i].orderKey = i
  }
}

async function onCardDelete(chapterId: string, cardId: number) {
  const cards = cardListCache.value[chapterId]
  const card = cards?.find(item => item.id === cardId)

  if (card == null || props.package == null) return

  const res = await deleteCard(props.package.id, chapterId, Number(cardId))

  if (res.code !== 0) {
    _message.info(res.message)
    return
  }

  const index = cards.findIndex(item => item.id === cardId)

  if (index > -1) {
    cards.splice(index, 1)

    onCardSelect(undefined)

    emit('chapterCountChange', chapterId, -1)
    _message.info(_t('删除成功'))
    return true
  }
}

function onCardsSortEnd({
  newIndex,
  oldIndex,
}: {
  newIndex: number
  oldIndex: number
}) {
  if (newIndex === oldIndex) return
  const focusedCardId = data.cards[data.cardFocus].id

  const sourceCard = data.cards[oldIndex]

  data.cards.splice(oldIndex, 1)
  data.cards.splice(newIndex, 0, sourceCard)

  // 如果移动的是一张草稿卡，则不需要调用接口，不影响
  if (sourceCard.id < 0) {
    return
  }

  const beforeCardId = data.cards[newIndex + 1]?.id

  if (beforeCardId != null) {
    moveCardPad(sourceCard.id, beforeCardId, 'before')
  } else {
    moveCardPad(sourceCard.id, beforeCardId, 'bottom')
  }

  data.cardFocus = data.cards.findIndex(item => item.id === focusedCardId)
}

async function moveCardPad(
  sourceCardId: number,
  targetCardId?: number,
  position?: DragPosition
) {
  await moveCard({
    sourcePkgId: props.package.id,
    cardId: sourceCardId,
    beforeCardId: position === 'bottom' ? undefined : targetCardId,
    sourceChapterId: data.selectedChapterId,
    targetChapterId: data.selectedChapterId,
  })
}

async function onDebugQueue() {
  if (!props.package) return

  const cards = await _openDialog<CardSchedule[]>(CardQueueSelect, {
    title: _t('选择卡片'),
    props: {
      packageId: props.package.id,
      chapterId: data.selectedChapterId,
      cards: data.cards,
    },
  })

  if (cards) {
    store.setStageUnit(
      {
        unitId: 0,
        isDebug: true,
        schedules: cards,
      },
      props.package.cardType
    )
    router.push({
      name: 'unit/learn',
    })
  }
}

function onDisplayMatchCard() {
  const cards = shuffle(data.cards)
    .slice(0, 5)
    .map(item => JSON.parse(item.content))
    .filter(card => card.type === CardType.EN_WORD) as EnWordCard[]

  if (cards.length === 0) {
    _message.info(_t('该章节不存在单词卡片'))
    return
  }

  const face = ref({
    type: VirtualCardType.Boss,
    name: randomPickBoss(),
    hp: 100,
    tickHp: 20,
    cardFace: {
      type: VirtualCardType.Match,
      cards: cards,
    },
  })
  const combo = ref(0)

  _openDialog(Boss, {
    title: _t('common.match_card'),
    rootClass: 'phone-container',
    props: {
      face: face.value,
      combo: combo.value,
      onMatchCorrect() {
        face.value.hp -= face.value.tickHp
        combo.value++
      },
    },
    dialog: {
      pt: {
        content: {
          style: 'padding: 0px;',
        },
      },
    },
  })
}

async function fetchCardList(chapterId: string) {
  if (chapterId == null) return

  if (data.cardListLoading.get(chapterId)) {
    return
  }

  try {
    if (cardListCache.value[chapterId] != null) {
      data.cards = cardListCache.value[chapterId].map(card => {
        return {
          renderId: getCardRenderId(),
          ...card,
        }
      })
    } else {
      data.cardListLoading.set(chapterId, true)
      const cardResList = (await fetchCards(props.package.id, chapterId)).data
        .cards
      cardListCache.value[chapterId] = cardResList
      cardResList.forEach(c => {
        store.setCardResponseCache(c.id, c)
      })

      if (data.selectedChapterId === chapterId) {
        data.cards = cardResList.map(card => {
          return {
            renderId: getCardRenderId(),
            ...card,
          }
        })
      }
    }

    if (data.selectedCardId) {
      const index = data.cards.findIndex(
        item => item.id > 0 && item.id.toString() === data.selectedCardId
      )
      data.cardFocus = index
    }

    fetchCardsLearnCount(data.cards.map(item => item.id))
  } finally {
    data.cardListLoading.set(chapterId, false)
  }
}

function fetchCardsLearnCount(cardIds: number[]) {
  if (cardIds.length === 0) return

  fetchCardsStats(cardIds).then(res => {
    data.cardLearnCountMap = mapValues(res.cardStatsMap, v => v.learnTimes)
  })
}

function onCardDistractorsUpdate(
  cardId: number,
  group: ClozeGroup,
  distractors: string[]
) {
  const index = data.cards.findIndex(item => item.id === cardId)

  if (index < 0) return

  const cardRes = data.cards[index]
  const card = JSON.parse(cardRes.content) as ClozeCard
  const newContent = updateClozeCardContentDistractors(card, group, distractors)
  const newCard = {
    ...card,
    content: newContent,
  }
  onCardResUpdate({
    ...cardRes,
    content: JSON.stringify(newCard),
  })
  onCardDraftUpdate(cardRes, index, newCard)
}

function onMCQCardUpdate(cardId: number, newCard: MCQCard) {
  const index = data.cards.findIndex(item => item.id === cardId)

  if (index < 0) return

  const cardRes = data.cards[index]
  onCardResUpdate({
    ...cardRes,
    content: JSON.stringify(newCard),
  })
  onCardDraftUpdate(cardRes, index, newCard)
}

function onChapterCardsFetch(chapterId: string) {
  fetchCardList(chapterId)
}

// 如果这个卡包是空的，则新建一张无法删除的草稿卡片
function createDraftCards() {
  if (props.package.cardCount === 0) {
    onCardDraftCreate(0)
  }
}

if (isOwner.value) {
  watch(
    () => [data.cards.length, props.package.cardCount],
    ([cardResLength, cardCount]) => {
      if (cardResLength === 0 && cardCount === 0) {
        createDraftCards()
      }
    }
  )
}

onInit(() => {
  fetchCardList(data.selectedChapterId)
})
function onUpdate(pkg: PackageBasic) {
  Object.assign(props.package, pkg)
}

const floatingPanelTop = ref<number>()
const panelWrapper = ref<HTMLDivElement>()
const panelContent = ref<HTMLDivElement>()
function alignDistractorPanelToEditor() {
  const focusedCard = document.querySelector(
    '.vue-recycle-scroller__item-view:has(.card-focus)'
  ) as HTMLDListElement

  // 悬浮窗的高度应该和当前卡片的高度水平对齐
  // 由于用了虚拟列表，所以这里的计算方式就等于卡片的视口顶部高度减去列表视口的顶部高度
  // 再加上一个 16px 的顶部 padding
  if (
    focusedCard &&
    slickList.value &&
    panelContent.value &&
    panelWrapper.value
  ) {
    const cardRect = focusedCard.getBoundingClientRect()
    const listRect = slickList.value.$el.getBoundingClientRect()

    // 这里要注意考虑 panelWraper 的高度
    let newTop = cardRect.top - listRect.top + 16
    const H = panelWrapper.value!.offsetHeight
    const h = panelContent.value!.offsetHeight
    if (newTop + h >= H) {
      newTop = H - h
    }

    floatingPanelTop.value = Math.max(newTop, 0)
  }
}

function onCardListScroll() {
  alignDistractorPanelToEditor()
}

watch(
  () => activeCard.value,
  () => {
    // 刚切换卡片的时，虚拟列表会重新进行计算，此时列表中每个 dom 的位置还不正确
    // 所以需要等待虚拟列表计算结束后再去给悬浮窗定位
    setTimeout(() => {
      alignDistractorPanelToEditor()
    }, 50)
  }
)

function onChallenge() {
  router.push({
    name: 'atlas',
    query: {
      pkgId: props.package.id,
    },
  })
}
</script>

<style scoped>
.header {
  height: 56px;
  padding: 0px 16px;
}

.mid-create-btn {
  height: 12px;
  display: flex;
  justify-content: center;
}

.mid-create-btn svg {
  opacity: 0;
  scale: 0;
  transition: scale 0.15s;
  transition-delay: 0.01s;
  cursor: pointer;
}

.mid-create-btn:hover svg {
  opacity: 1;
  scale: 1.5;
}

.card-focus {
  border-color: var(--ld-brand-500);
}
</style>
